<template>
  <div class="card card-custom card-transparent">
   
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps" style="justify-content: center;">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
              style="flex: 100%"
            >
              <div class="wizard-wrapper">
                <!-- <div class="wizard-number">
                  1
                </div> -->
                <div class="wizard-label">
                  <div class="wizard-title">
                    <span><h3>User Manajemen</h3></span>
                  </div>
                  <div class="wizard-desc">
                    <span>Tambah Data User</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <v-app>
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-12">
                  <!--begin: Wizard Form-->
                  <!-- <form class="form mt-0 mt-lg-10" id="kt_form"> -->
                    <v-progress-linear
                      indeterminate
                      color="pink darken-2"
                      v-show="!loadForm"
                      style="height:15px;"
                    ><div><span>Proses Data...</span></div></v-progress-linear>
                    <!--begin: Wizard Step 1-->
                  <v-form ref="form" v-model="valid" v-show="loadForm" lazy-validation>
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                    <div class="row">
                     <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pb-0 mb-0 text-center"
                      >
                        <div class="form-group">
                            <!-- <label class="col-xl-3 col-lg-3 col-form-label text-right"
                                >Foto Profil</label
                            > -->
                            <!-- <div class="col-lg-9 col-xl-6"> -->
                              <div class="image-input image-input-outline" id="kt_profile_avatar">
                                <div
                                    class="image-input-wrapper"
                                    :style="{ backgroundImage: `url(${current_photo})` }"
                                    style="width: 200px; height: 200px;"
                                ></div>
                                <label
                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Change avatar"
                                >
                                    <i class="fa fa-pen icon-sm text-muted"></i>
                                    <input
                                    type="file"
                                    name="profile_avatar"
                                    id="profile_photo"
                                    accept=".png, .jpg, .jpeg"
                                    @change="onFileChange($event)"
                                    />
                                    <input type="hidden" name="profile_avatar_remove" />
                                </label>
                                <span
                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="cancel"
                                    data-toggle="tooltip"
                                    title="Cancel avatar"
                                >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                <span
                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="remove"
                                    data-toggle="tooltip"
                                    title="Remove avatar"
                                    @click="removeImage"
                                >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                </div>
                                <span class="form-text text-muted"
                                >Allowed file types: png, jpg, jpeg.</span
                                >
                            <!-- </div> -->
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nama</label>
                        <v-text-field
                          v-model="formItem.name"
                          :rules="idFormBiasa"
                          id="name"
                          label="Nama"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Username</label>
                        <v-text-field
                          :disabled="disableOnEdit == 1"
                          v-model="formItem.username"
                          :rules="idFormBiasa"
                          id="username"
                          label="Username"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <div class="form-group">
                          <label>Kategori User</label>
                            <v-select
                              v-model="formItem.level"
                              :items="itemLevel"
                              item-value="id"
                              item-text="level"
                              label="Kategori User"
                              clearable
                              solo
                              required
                            ></v-select>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Jabatan</label>
                        <v-text-field
                          v-model="formItem.jabatan"
                          :rules="idFormBiasa"
                          id="jabatan"
                          label="Jabatan"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Alamat</label>
                        <v-textarea
                          v-model="formItem.alamat"
                          label="Alamat"
                          clearable
                          solo
                          ></v-textarea>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Email</label>
                        <v-text-field
                          :disabled="disableOnEdit == 1"
                          v-model="formItem.email"
                          :rules="idFormBiasa"
                          id="email"
                          label="Email"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nomor HP</label>
                        <v-text-field
                          v-model="formItem.no_hp"
                          type="number"
                          :rules="idFormBiasa"
                          id="no_hp"
                          label="Nomor HP"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                        <div class="form-group">
                            <label>Password Baru</label>
                            <!-- <div class="col-lg-9 col-xl-6"> -->
                                <v-text-field
                                v-model="formItem.password"
                                type="password"
                                id="password"
                                label="Password"
                                clearable
                                required
                                solo
                                ></v-text-field>
                                <!-- <input
                                v-model="formItem.password"
                                type="password"
                                class="form-control form-control-lg form-control-solid"
                                value=""
                                placeholder="Password Baru"
                                name="new_password"
                                ref="new_password"
                                /> -->
                            <!-- </div> -->
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                       <div class="form-group">
                            <label >Ulangi Password</label>
                            <!-- <div class="col-lg-9 col-xl-6"> -->
                            <v-text-field
                                type="password"
                                id="password"
                                label="Password"
                                clearable
                                required
                                solo
                            ></v-text-field>
                                <!-- <input
                                type="password"
                                class="form-control form-control-lg form-control-solid"
                                value=""
                                placeholder="Ulangi Password"
                                name="verify_password"
                                ref="verify_password"
                                /> -->
                            <!-- </div> -->
                        </div>
                      </v-col>
                    </div>
                    </div>
                  </v-form>
                <!-- </div>
              </div> -->
            <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10" v-show="loadForm">
                        <div class="mr-2">
                          <v-btn
                              rounded
                              color="#ffa69e"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="goTable()"
                          >
                              Kembali
                          </v-btn>
                        </div>
                      <div>
                         <v-btn
                              rounded
                              color="#fec5bb"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="save(formItem)"
                          >
                              Simpan
                          </v-btn>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  <!-- </form> -->
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
        </v-app>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
    
  </div>
</template>

<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";
var CryptoJS = require("crypto-js");

export default {
  name: "Wizard-4",
  data(){
    return{
      valid: true,
      formItem: {
        name: '',
        email: '',
        username: '',
        password: '',
        jabatan: '',
        no_hp: '',
        alamat: '',
        level: '',
        password_not_change: ''
      },
      
      idFormBiasa: [
        v => !!v || 'Mohon Diisi !'
      ],
      flag: "",
      // ableWizard: true,
      idData: '',
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      dFormUser: [],
      tdFormUser: 0,
      progressBar: false,
      loadForm: false,
      isFirstLoad: true,
      default_photo: "media/users/blank.png",
      current_photo: null,
      profile_photo: null,
      baseUrlUpload: ApiService.getBaseUrlUpload(),
      isRemoveImg: 0,
      itemLevel: [
        {id: 0, level: "Admin"},
        {id: 1, level: "User"},
      ],
      disableOnEdit: 0
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" }
    ]);
    
    // console.log(this.$route.query.dPerseroan)
    this.flag = this.$route.query.flag

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: this.flag == "isUpdate" ? true : false // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  watch: {
    options: {
        async handler (val) {
            val
            if(!this.isFirstLoad && this.flag == "isUpdate") 
              await this.getDataFormUser()
        },
        deep: true,
    },
    flag(val){
      if(val == 'isCreate'){
        this.isCreate()
        this.disableOnEdit = 0
      }else if(val == 'isUpdate' && this.isFirstLoad){
        this.idData = this.$route.query.idData
        this.load2()
        this.disableOnEdit = 1
      }
    }
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    isCreate(){
      this.loadForm = true
      this.current_photo = this.default_photo
    },
    async save (data_save){
      var saveObj = {}
      var _this = this
      if( _this.$refs.form.validate()){
        if(this.flag == 'isCreate'){
          saveObj = Object.assign({}, data_save)
          saveObj.UID = storagelocal.getLocalStorage('UID')
          saveObj.Token = storagelocal.getLocalStorage('token')
          saveObj.source_upload = this.profile_photo
          // saveObj.level = 1
          saveObj.password  = CryptoJS.SHA256(this.formItem.password).toString()
          saveObj.Trigger = 'C'
        }else{
          saveObj = Object.assign({}, data_save)
          saveObj.UID = storagelocal.getLocalStorage('UID')
          saveObj.Token = storagelocal.getLocalStorage('token')
          saveObj.source_upload = this.profile_photo
          if(this.formItem.password != ''){
            saveObj.password  = CryptoJS.SHA256(this.formItem.password).toString()
          }else{
            saveObj.password = this.formItem.password_not_change
          }
          if(this.isRemoveImg)
            saveObj.path_file = ''
          saveObj.Trigger = 'U'
          // saveObj.level = 1
          saveObj.id = this.idData
        }
        Promise.all([await this.saveAll(saveObj)])
          .then(async (results8)=>{
          results8
          await this.getDataFormUser()
          // storagelocal.setLocalStorage('photo_conv', this.current_photo)
        //   window.location.reload();
          // this.loadPage()
        })
      }
    },
    saveAll(saveObj){
      return new Promise(resolve => {
          resolve, saveObj
            // this.ableWizard = false;
            Services.PostData(ApiService, "users", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                Swal.fire({
                    title: "Berhasil",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 2000
                });
                // this.$router.push({name: 'tableuser'})
              }else{
                Swal.fire({
                    title: "Berhasil",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 2000
                });
              }
            }, err =>{
              err
            }) 
        });
    },
    getDataFormUser(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options,
          id              : this.idData
      }
      this.loadForm = false
      Services.PostData(ApiService, "users", mydata, async response=>{
        if(response.status == 1000){
          this.loadForm = true
          var responseCace = response.data[0]
          this.formItem = Object.assign({}, responseCace)
          this.formItem.password = "";
          if(responseCace.path_file != null){
            this.current_photo = await this.getPhotoProfile(responseCace.path_file, this.baseUrlUpload)
            // storagelocal.setLocalStorage('photo', responseCace.path_file)
          }else{
             this.current_photo = this.default_photo
          }
          this.formItem.password_not_change = responseCace.password
          if(this.idData == storagelocal.getLocalStorage('UID')){
              if(responseCace.path_file != null){
                var photoBlob = await this.getPhotoProfile(responseCace.path_file, this.baseUrlUpload)
                storagelocal.setLocalStorage('photo_conv', photoBlob)
              }
          }
        }else{
          Swal.fire({
              title: "Gagal",
              text: "Gagal Memuat Data",
              icon: "warning",
              heightAuto: false,
          });
        }
          resolve(responseCace);
          }, err =>{
          err
          })
      });
    },
    goTable(){
      this.$router.push({name: 'tableuser'})
    },
    async load2(){
      Promise.all([
                    await this.getDataFormUser()
                  ])
      .then( (results)=> {
       
        this.isFirstLoad = false
        results
      })
    },
    onFileChange(e) {
        var files = ''

        if(!e)
        return

        files = e
        this.isRemoveImg = 1
        this.createImage(files);
    },
    createImage(files) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;
      image
      reader.onload = (e) => {
        vm.current_photo = e.target.result;
      };
      if (files) {
          reader.readAsDataURL(files.target.files[0]);
      }
      this.profile_photo = files.target.files[0]
    },
    removeImage: function (e) {
      e
      this.current_photo = this.default_photo;
      this.profile_photo = '';
      this.isRemoveImg = 1
    },
    getBlobFromUrl(myImageUrl) {
        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('GET', myImageUrl, true);
            request.responseType = 'blob';
            request.onload = () => {
                resolve(request.response);
            };
            request.onerror = reject;
            request.send();
        })
    },
    async getPhotoProfile(item, baseUrlUpload){
        let myBlob = ''
        if(item != null) {
          myBlob = await this.getBlobFromUrl(baseUrlUpload + '/' + item);
        }
        return this.createImageProfile(myBlob)
    },
    createImageProfile(files) {
      if (files) {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        // this.profile_photo = convphoto
        return new Promise(resolve => {
          reader.onload = (e) => {
              resolve( e.target.result)
          };
        })
      }
    },
  }
};
</script>
